<template>
  <div class="ticket-msg">
    <div class="row">
      <div class="col-3 col-sm-2 col-md-1 pe-0">
        <v-avatar :size="48" rounded :src="agentPhoto(ticketMsg.userPhoto)" />
      </div>
      <div class="col-9 col-sm-10 col-md-10 ps-0 my-auto">
        <div class="ticket-msg-author">
          {{ ticketMsg.name }} <span>{{ ticketMsg.position }}</span>
        </div>
        <div class="ticket-msg-date">
          {{ ticketMsg.date | format12Date }}
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="ticket-msg-text">
        <p v-html="ticketMsg.msg"></p>
      </div>
    </div>
    <div class="col-12" v-if="ticketMsg.files.length > 0">
      <div class="attach-file mb-2" v-for="file in ticketMsg.files" :key="file.id">
        <div class="">
          <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
          <span class="file-name" :content="file.name" v-tippy>
            {{ file.name }}
          </span>
        </div>
        <div class="file-controls d-flex">
          <button class="btn btn-control" @click="downloadFile(file.id)">
            <img src="@/assets/icons/icon-download.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {getBackendUrl} from "@/utils/backendUrl";

export default {
  name: "TicketMsg",
  props: ["ticketMsg"],
  methods: {
    downloadFile(id) {
      window.location.href = getBackendUrl() + `/api/v1/resolution-center/${id}/download?disposition_type=attachment`;
    }
  },
  filters: {
    format12Date(value) {
      if (value) {
        return moment(String(value)).format("MMM DD, YYYY hh:mm a");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ticket-msg {
  &:nth-child(n + 2) {
    margin-top: 18px;
  }
  border-bottom: 1px solid #dadada;

  &-author {
    color: #065ae4;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;

    span {
      display: inline-block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 6px;
    }
  }

  &-date {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 2px;
  }

  &-text > p {
    display: block;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 18px;
    white-space: pre-line;
  }
}
</style>
