<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'resolution_center' }">
          <button class="btn btn-control">
            <img
              alt="Return back"
              src="@/assets/icons/bold-icon previous.svg"
            />
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">
          {{ showRawContent(ticketData.title, 70) }}
        </h3>
      </div>
      <div class="btn-group dropright" v-if="permissions.isAdmin || permissions.isAssignee">
        <button type="button" class="btn btn-secondary" :disabled="ui.generation"
            @click="changeStatus(ticketData.status == 'Pending' ? 'open' : 'solved')">
          <span
            v-if="ui.generation"
            aria-hidden="true"
            class="spinner-border spinner-border-sm"
            role="status"
          >
          </span>
          <span v-else>
            {{ ticketData.status == 'Pending' ? 'Open this ticket' : 'Mark as Resolved' }}
          </span>
        </button>
        <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
          @click="ui.openMarkAsModal = !ui.openMarkAsModal"></button>
        <transition name="fade">
          <mark-as-modal v-if="ui.openMarkAsModal"
            :status="ticketData.status"
            @success="changeStatus"
          />
        </transition>
      </div>
    </div>

    <div class="page-breadcrumbs">
      <router-link :to="{ name: 'resolution_center' }">
        Resolution Center
      </router-link>
      <span>
        /
      </span>
      <span> Ticket #{{ ticketData.id }} </span>
    </div>

    <page-spinner v-if="ui.loading" />

    <!-- Page Content -->
    <div class="page-content" v-else>
      <div class="row">
        <div class="col-md-8">
          <div class="tickets">
            <div class="tickets-items">
              <ticket-msg
                v-for="ticket in ticketMsgs"
                :ticket-msg="ticket"
                :key="ticket.id"
              />
            </div>
            <div class="form-group">
              <ckeditor
                :editor="editor"
                v-model="formData.msg"
                id="fieldContent"
                :config="editorConfig"
              >
              </ckeditor>
              <file-field
                :multiple="false"
                :files="formData.files"
                v-if="ui.showUploader"
                @change="onFileChange"
                class="mb-2 mt-2"
              />

              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-control"
                  @click="ui.showUploader = !ui.showUploader"
                >
                  <img src="@/assets/icons/icon-attachment-18.svg" alt="" />
                </button>
                <base-button title="Submit" action="secondary-default" @click-btn="sendMsg" :disabled="formData.msg == '' || ui.sending"
                             :loading="ui.sending" size="sm" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="tickets-info">
            <div class="d-flex">
              <div class="tickets-info-title">Id</div>
              <div class="tickets-info-text">{{ ticketData.id }}</div>
            </div>
            <div class="d-flex">
              <div class="tickets-info-title">Status</div>
              <div class="tickets-info-text">
                <div class="status" :class="{
                open: ticketData.status === 'Open',
                pending: ticketData.status === 'Pending',
                closed: ticketData.status === 'Closed',
                solved: ticketData.status === 'Solved'
              }" >{{ ticketData.status }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div class="tickets-info-title">Inquiry Type</div>
              <div class="tickets-info-text">
                <div class="ticket-status"
                :class="{
                  payment: ticketData.type === 'Payment',
                  general: ticketData.type === 'General',
                  technical: ticketData.type === 'Technical'
                }">{{ ticketData.type ? ticketData.type : 'N/A' }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div class="tickets-info-title">Created</div>
              <div class="tickets-info-text">{{ ticketData.createdAt | format24Date }}</div>
            </div>
            <div class="d-flex">
              <div class="tickets-info-title">Last Activity</div>
              <div class="tickets-info-text">{{ ticketData.updatedAt | format24Date }}</div>
            </div>
            <div class="d-flex">
              <div class="tickets-info-title">Requester</div>
              <div class="tickets-info-text">{{ ticketData.requester }}</div>
            </div>
          </div>
          <div class="tickets-info mt-3">
            <div class="d-flex align-items-start">
              <div class="tickets-info-title">
                Assign to
              </div>
              <div class="tickets-info-text row">
                <div
                  class="assigned-users col-12 p-2 d-flex"
                  v-for="(assienee, index) in ticketData.assignees"
                  :key="index"
                >
                  <img
                    :src="agentPhoto(assienee.avatar)"
                    class="user-avatar"
                    alt=""
                  />
                  <span class="user-name">{{ assienee.name }}</span>

                  <button v-if="permissions.isAdmin" type="button" class="btn btn-control me-2" @click="removeAgent(assienee.id)">
                    <img src="@/assets/icons/delete.svg" alt="">
                  </button>
                </div>
                <div class="mt-2" v-if="permissions.isAdmin">
                  <div
                    class="assign-user"
                    v-if="!ui.assigning"
                    @click="ui.assigning = true"
                  >
                    + Assign
                  </div>
                  <div v-else>
                    <multiselect
                      v-model="assignedAgent"
                      placeholder="Select Agent"
                      id="fieldAgents"
                      track-by="id"
                      label="name"
                      class="full-width mb-3"
                      :options="agents"
                      :searchable="true"
                      :close-on-select="true"
                      :allow-empty="false"
                      :show-labels="false"
                    >
                    </multiselect>
                    <div class="d-flex">
                      <base-button title="Assign" action="secondary-default" @click-btn="assignAgent" :loading="ui.buttonAssigning" size="sm" />
                      <base-button title="Cancel" action="secondary" @click-btn="ui.assigning = false" class="ms-2" size="sm" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileField from "../../components/form/FileField.vue";
import PageSpinner from "../../components/pageSpinner.vue";
import TicketMsg from "./includes/TicketMsg.vue";
import MarkAsModal from "./modals/MarkAsModal"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import moment from "moment";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: "ResolutionCenterTicket",
  components: {
    TicketMsg,
    PageSpinner,
    FileField,
    Multiselect,
    MarkAsModal,
    BaseButton
  },
  data() {
    return {
      ui: {
        loading: false,
        generation: false,
        showUploader: false,
        sending: false,
        assigning: false,
        buttonAssigning: false,
        openMarkAsModal: false
      },
      ticketData: {
        id: this.$route.params.id,
        title: "..."
      },
      assignedAgent: "",
      ticketMsgs: [],
      agents: [],
      formData: {
        files: [],
        msg: ""
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: []
      },
      permissions: {
        isAdmin: false,
        isAssignee: false
      }
    };
  },
  methods: {
    onFileChange(e) {
      let length = e.target.files.length
      this.formData.files = [];
      for (let i = 0; i < length; i++) {
        this.formData.files.push(e.target.files[i])
      }
    },
    changeStatus(status) {
      this.ui.openMarkAsModal = false
      this.ui.generation = true
      let formData = new FormData()
      formData.append('status', status.toLowerCase())
      this.$http
        .post(`/api/v1/resolution-center/${this.ticketData.id}/update-status`, formData)
        .then(() => this.getTicketData())
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.generation = false
        });
    },
    removeAgent(id) {
      if(confirm("Are you sure?")){
        this.ui.loading = true
        let formData = new FormData()
        formData.append('agentId', id)
        this.$http
        .post(`/api/v1/resolution-center/${this.ticketData.id}/remove-agent`, formData)
        .then(() => this.getTicketData())
        .catch(err => {
          this.alertError(err.response.statusText);
        })
      }
    },
    getTicketData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/resolution-center/${this.ticketData.id}/show`)
        .then(res => {
          this.permissions = res.data.permissions;
          this.ticketData = res.data.ticketData;
          this.ticketMsgs = res.data.ticketMessages;
          this.agents = res.data.agents;
          this.assignedAgent = res.data.agents[0]
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    sendMsg() {
      this.ui.sending = true;
      let formData = new FormData();
      formData.append('message', this.formData.msg)
      for (let file of this.formData.files) {
        formData.append('file[]', file)
      }

      this.$http
        .post(`/api/v1/resolution-center/${this.ticketData.id}/comment`, formData)
        .then(() => {
          this.ui.showUploader = false
          this.formData = {
            files: [],
            msg: ""
          }
          this.getTicketData()
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.sending = false;
        });
    },
    assignAgent() {
      this.ui.buttonAssigning = true;

      let formData = new FormData();
      formData.append('agentId', this.assignedAgent.id)
      this.$http
        .post(`/api/v1/resolution-center/${this.ticketData.id}/assign`, formData)
        .then(res => {
          this.assignedAgent = ""
          this.ui.assigning = false
          this.alertSuccess(res.data.message);
          this.getTicketData()
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.buttonAssigning = false;
        });
    },
    showRawContent(value, limit) {
      if (value) {
        let filteredData = value.replace(/<\/?[^>]+>/gi, " ");
        return filteredData.split("&nbsp;").join(" ").substring(0, limit) + "..";
      }
      return ".."
    }
  },
  beforeMount() {
    this.getTicketData();
  },
  filters: {
    format24Date(value) {
      if (value) {
        return moment(String(value)).format('MMM DD, YYYY HH:mm');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  &-title {
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
  button {
    &.bg-green {
      min-width: 100px;

      @media (max-width: 1024px) {
        padding: 0;
      }
    }
  }
}

.btn-cancel {
  margin-left: 8px;
}

.page-content {
  padding: 24px;

  ::v-deep {
    .multiselect {
      height: 36px;
      width: 200px;

      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &.multiselect--active {
        .multiselect__tags {
          transition: all 0.3s;
          border: 1px solid rgba(38, 111, 229, 0.5);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__tags {
        min-height: 36px;
        max-height: 36px;
        padding: 6px 40px 0 8px;
        transition: all 0.3s;

        &:hover {
          border: 1px solid rgba(38, 111, 229, 0.2);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__select {
        min-height: 36px;
        max-height: 36px;

        &:before {
          top: 15%;
          position: relative;
          right: 0;
          color: #999;
          border: solid rgba(0, 0, 0, 0.46);
          margin-top: 4px;
          border-width: 0px 1px 1px 0;
          display: inline-block;
          padding: 3px;
          content: "";
          transform: rotate(44deg);
        }
      }

      &__single {
        color: rgba(0, 0, 0, 0.46);

        @media screen and (max-width: 568px) {
          font-size: 9px;
          white-space: nowrap;
        }
      }

      &__option {
        padding: 10px 12px;
        min-height: 36px;
        transition: all 0.3s;
        line-height: 20px;
        font-size: 14px;
        color: #000;

        @media screen and (max-width: 568px) {
          font-size: 9px;
        }

        &--highlight {
          background: #f3f3f3;
          line-height: 20px;
          font-size: 14px;
          color: #000;

          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }
      }
    }
  }

  .tickets {
    border: 1px solid #dadada;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 30px;

    &-items {
      max-height: 850px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &-info {
      border: 1px solid #dadada;
      padding: 22px 18px;
      border-radius: 4px;

      .d-flex {
        align-items: center;
        padding: 10px 0;

        .ticket-status {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          padding: 4px 9px;
          width: fit-content;
          margin-top: 6px;
          border-radius: 2px;

          &.payment {
            color: #00ab34;
            background-color: rgba(0, 171, 52, 0.1);
          }

          &.general {
            color: #4c4c4c;
            background-color: rgba(146, 146, 146, 0.1);
          }

          &.technical {
            color: #ff7300;
            background: rgba(255, 115, 0, 0.1);
          }
        }

        .tickets-info-title {
          width: 30%;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 21px;
          height: 100%;
        }

        .tickets-info-text {
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
          max-width: 74%;

          .assigned-users {
            min-height: 50px;
            min-width: 227px;
            button {
              margin-left: auto;
              transition: all .3s;
              display: none;
            }

            &:hover {
              background-color: #F6F6F6 !important;
              button {
                display: flex;
              }
            }
          }

          .assign-user {
            color: #065ae4;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 21px;
            cursor: pointer;
          }

          .user-avatar {
            width: 25px;
            height: 25px;
            border: 1px solid #cccccc;
            object-fit: cover;
            border-radius: 50%;
          }

          .user-name {
            display: inline-block;
            color: #000000;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            margin-left: 6px;
          }
        }
      }
    }

    .form-group {
      max-width: 100%;
      margin-top: 18px;

      textarea {
        width: 100%;
      }
    }
  }

  ::v-deep {
    .ck.ck-editor__main > .ck-editor__editable {
      min-height: 110px !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
    .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
</style>
