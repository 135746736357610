<template>
    <div class="dropdown-menu show">
        <h6 class="dropdown-header">Mark as</h6>
        <span class="dropdown-item" @click="$emit('success', 'pending')" v-if="status != 'Pending'">Pending</span>
        <span class="dropdown-item" @click="$emit('success', 'closed')" v-if="status != 'Closed'">Closed</span>
        <span class="dropdown-item" @click="$emit('success', 'solved')" v-if="status != 'Resolved'">Resolved</span>
    </div>
</template>

<script>
export default {
  name: "MarkAsModal",
  props: {
      status: String
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
    &-menu {
        right: 0px;
        min-width: 12rem;
        font-size: 14px;
    }

    &-header {
        font-size: 12px;
        opacity: 0.8;
    }

    &-item {
        cursor: pointer;
    }
}
</style>